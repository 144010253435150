import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import FAQInvestSection from "../components/static-sections/FAQInvestSection"
import InvestLegalSection from "../components/static-sections/InvestLegalSection"
import InvestApplyMockConcept from "../components/static-sections/InvestApplyMockConcept"
import InvestOfferings from "../components/static-sections/InvestOfferings"
import IraOfferings from "../components/static-sections/IraOfferings"
import HeroInvestGallery from "../components/static-sections/HeroInvestGallery"

/** dynamic sections */
import HeroBottomInvestTextSection from "../components/dynamic-sections/HeroBottomInvestTextSection"
import HeroInvestImageHero from "../components/dynamic-sections/HeroInvestImageHero"
import HeroVideo from "../components/dynamic-sections/HeroVideo"
import HeroImage from "../components/dynamic-sections/HeroImage"
import HeroInvestImage from "../components/dynamic-sections/HeroInvestImage"
import InvestRibbionSignup from "../components/dynamic-sections/InvestRibbionSignup"
import HeroBottomInvestLandingCustomSection from "../components/dynamic-sections/HeroBottomInvestLandingCustomSection"
import TestimonialSection from "../components/dynamic-sections/testimonial-setcion/TestimonialSection"
import InvestCalculatorSectionNew from "../components/dynamic-sections/InvestCalculatorSectionNew"
import InvestStats from "../components/dynamic-sections/InvestStats"
import InvestRates from "../components/dynamic-sections/InvestRates"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import HeroInvestRightSection from "../components/dynamic-sections/HeroInvestRightSection"
import HeroInvestLinkButtons from "../components/dynamic-sections/HeroInvestLinkButtons"

/** svg */
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const InvestLandingPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/invest/",
          name: "Norhart Invest and Hutch| Norhart",
          image: `${config.siteMetadata.siteUrl}/influencers/norhart-invest-hutch-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Invest and Hutch | Norhart"
      description="Norhart invest platform. Our invest platform will offer up to 10% APY interest rate returns, and all investments are backed by real estate."
      keywords="norhart, invest, investment, promissory note, real estate, investment, fintech, tax benefits, wealth"
      imageTwitter={`${config.siteMetadata.siteUrl}/influencers/norhart-invest-hutch-landing-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/influencers/norhart-invest-hutch-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestLinkButtons />

      <HeroInvestImageHero imageHero="/influencers/hutch-hero.png" />

      {/* <HeroInvestLinkButtons /> */}

      {/* <HeroInvestImage
        items={[
          {
            image: "/invest/norhart-tech-hero-1-min.png",
          },
          {
            image: "/invest/norhart-tech-hero-2-min.png",
          },
          {
            image: "/invest/norhart-tech-hero-3-min.png",
          },
          {
            image: "/invest/norhart-tech-hero-5-min.png",
          },
        ]}
      /> */}

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <InvestCalculatorSectionNew
        backgroundColorOne="#e1e1e1"
        backgroundColorTwo="#e1e1e1"
        title="How Much Can You Make"
        subTitle="Select an amount to calculate your potential Norhart Invest return."
      />

      <InvestRates />

      <HeroInvestImageHero imageHero="/invest/norhart-invest-grow-your-cash-overtime.png" />

      <HeroBottomInvestTextSection
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="Norhart Promissary Notes"
        title="Why Invest With Us"
        subTitle="Our promissory notes fund tangible residential real estate projects. We convert your capital into something you can see and touch. With us, your money makes better homes, lives, and dreams for people."
        subNote="Hours: M-F (8 AM - 8 PM), Saturday (10 AM - 4 PM), Sunday - (Closed)"
        image=""
        imageAlt="Norhart Invest How It Works"
        textColor="#111111"
        asoEffect="fade-in"
        buttonName="Book A Call"
        buttonUrl="https://outlook.office365.com/owa/calendar/NorhartInvest3@norhart.com/bookings/"
      />

      <HeroInvestImageHero imageHero="/invest/gallery/oakdale/norhart-oakdale-luurxy-loft-penthouses-project.png" />

      <HeroBottomInvestLandingCustomSection
        backgroundColorOne="#f4f4f4"
        backgroundColorTwo="#f4f4f4"
        icon="{NorhartBlackCircleIcon}"
        iconTitle="Norhart Invest"
        title="We Are Changing An Industry"
        subTitle="That Hasn't Changed
        In Decades"
        image="/invest/norhart-invest-capital-flow.png"
        imageAlt="Norhart CEO Mike Kaeding Video"
        textColor="#111111"
        calcultateColor="#198754"
        inputColor="#198754"
      />

      <InvestStats />

      <HeroImage
        items={[
          {
            name: "Mike Kaeding",
            title: "CHIEF EXECUTIVE OFFICER",
            image: "/invest/leadership/mike-kaeding-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/mikekaeding/",
          },
          {
            name: "Stacy Ekholm",
            title: "CHIEF OPERATING OFFICER",
            image: "/invest/leadership/stacy-ekholm-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/stacy-ekholm-cpm-cos-crm-cmca-a587647b/",
          },
          {
            name: "Tim Libertini",
            title: "CHIEF FINANCIAL OFFICER",
            image: "/invest/leadership/tim-libertini-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/timothy-libertini-81701744/",
          },
          {
            name: "Marie Dickover",
            title: "CHIEF DEVELOPMENT OFFICER",
            image: "/invest/leadership/marie-dickover-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/marie-dickover-178095225/",
          },
        ]}
      />

      <InvestOfferings />

      <HeroVideo
        title="Tailored Benefits For You"
        subtitle="At Norhart, we are committed to solving housing affordability. We fuse extensive expertise in uncovering real estate investment prospects with the warmth of a family-run enterprise."
        buttonName1="Invest Now"
        buttonUrl1="https://invest.norhart.com/signup"
        videoID="2"
        videoFile="/invest/video/norhart-msp.mp4"
      />

      <HeroBottomInvestTextSection
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="Norhart IRA"
        title="Seamless IRA & 401K Investing"
        subTitle="Unlock 10% returns for your retirement with Norhart IRA. Democratizing investments for all. Secure your prosperous future today."
        subNote="Hours: M-F (8 AM - 8 PM), Saturday (10 AM - 4 PM), Sunday - (Closed)"
        image=""
        imageAlt="Norhart Invest How It Works"
        textColor="#111111"
        asoEffect="fade-in"
        buttonName="Book A Call"
        buttonUrl="https://outlook.office365.com/owa/calendar/NorhartInvestIRA401KRollover@norhart.com/bookings/"
      />

      <HeroInvestImageHero imageHero="/invest/gallery/oakdale/norhart-oakdale-penthouse-owners-bedroom-project.png" />

      <InvestRibbionSignup
        company="NORHART INVEST"
        title="Tailored Benefits"
        subtitle="Norhart Invest has expert evaluations, cutting-edge technology, short and long-term options, and a great investment pathway."
        buttonName="Invest Now"
        buttonUrl="https://invest.norhart.com/signup"
      />

      <HeroInvestGallery
        image1="/invest/gallery/oakdale/norhart-oakdale-lobby.png"
        imageAlt1="Norhart Oakdale MN Apartment Lobby"
        image2="/invest/gallery/oakdale/norhart-oakdale-pool.png"
        imageAlt2="Norhart Oakdale MN Apartment Pool"
        image3="/invest/gallery/oakdale/norhart-oakdale-lifestyle-1.jpg"
        imageAlt3="Norhart Oakdale Lifestyle Living"
        image4="/invest/gallery/oakdale/norhart-oakdale-lifestyle-2.jpg"
        imageAlt4="Norhart Oakdale Lifestyle Living"
        image5="/invest/gallery/oakdale/norhart-oakdale-lifestyle-3.jpg"
        imageAlt5="Norhart Oakdale Lifestyle Living"
        image6="/invest/gallery/oakdale/norhart-oakdale-lifestyle-4.jpg"
        imageAlt6="Norhart Oakdale Lifestyle Living"
      />

      <InvestRibbionSignup
        company="NORHART INVEST"
        title="Invest With Confidence"
        subtitle="You will find comfort and stability with Norhart Apartments, Norhart Construction, and Norhart Invest. We are a real company, you know, in Minneapolis."
        buttonName="Invest Now"
        buttonUrl="https://invest.norhart.com/signup"
      />

      <HeroVideo
        title="Invest From Anywhere In The USA*"
        subtitle="Our investor presence spans from coast to coast. While we're not active in Washington, our commitment to serving investors and venturesome minds remains unwavering."
        buttonName1="Book A Call"
        buttonUrl1="https://outlook.office365.com/owa/calendar/NorhartInvest3@norhart.com/bookings/"
        buttonName2="Invest Now"
        buttonUrl2="https://invest.norhart.com/signup"
        videoID="1"
        videoFile="/invest/video/usa.mp4"
      />

      {/* <TestimonialSection /> */}

      <FAQInvestSection />

      <HeroInvestRightSection
        backgroundColorOne="#e1e1e1"
        backgroundColorTwo="#e1e1e1"
        fontTextColor="#333333"
        titleTopLeft="Security Reports"
        badgeTitle="Transparency"
        title="Security Posture"
        subtitle="Verify our security report, SSL report and our server uptime reports."
        imageTitle="Norhart Website Pingdom Uptime Report"
        image="/security/norhart-security-pingdom-uptime-report.png"
        buttonName="Security"
        buttonUrl="https://securityheaders.com/?q=https%3A%2F%2Finvest.norhart.com&followRedirects=on"
        buttonName2="Uptime"
        buttonUrl2="http://stats.pingdom.com/i6owumclg489"
        buttonName3="SSL"
        buttonUrl3="https://www.ssllabs.com/ssltest/analyze.html?d=invest.norhart.com&s=65.8.161.56&latest"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      <InvestLegalSection />
    </Layout>
  )
}

/** export */
/** export */
export default InvestLandingPage
