import React from "react"
import { Carousel, Container } from "react-bootstrap"

interface Props {
  items: {
    buttonName: string
    buttonUrl: string
    buttonName2: string
    buttonUrl2: string
  }[]
  url?: string
}

/** const */
const HeroInvestLinkButtons: React.FC<Props> = ({ items }) => {
  return (
    <div className="text-center pt-3 pb-2" style={{ background: "#000000" }}>
      <div>
        <div className="d-flex justify-content-center mt-0 mb-0">
          <a
            className="btn btn-sm"
            href="https://invest.norhart.com/signin"
            target="_blank"
            style={{
              backgroundColor: "#FFFFFF",
              border: "0px",
              color: "#555555",
              fontWeight: "BOLD",
            }}
          >
            Sign In Today
          </a>

          <span
            style={{
              padding: "10px",
            }}
          ></span>

          <a
            className="btn btn-sm"
            href="https://invest.norhart.com"
            target="_blank"
            style={{
              backgroundColor: "#5cb85c",
              border: "0px",
              color: "#FFFFFF",
              fontWeight: "BOLD",
            }}
          >
            Open Account
          </a>
        </div>

        <hr />
      </div>
    </div>
  )
}

/** export */
export default HeroInvestLinkButtons
